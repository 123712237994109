// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacidade-tsx": () => import("./../../../src/pages/privacidade.tsx" /* webpackChunkName: "component---src-pages-privacidade-tsx" */),
  "component---src-pages-produtos-index-tsx": () => import("./../../../src/pages/produtos/index.tsx" /* webpackChunkName: "component---src-pages-produtos-index-tsx" */),
  "component---src-pages-produtos-mr-1-index-tsx": () => import("./../../../src/pages/produtos/mr1/index.tsx" /* webpackChunkName: "component---src-pages-produtos-mr-1-index-tsx" */),
  "component---src-pages-produtos-mr-1-novidades-tsx": () => import("./../../../src/pages/produtos/mr1/novidades.tsx" /* webpackChunkName: "component---src-pages-produtos-mr-1-novidades-tsx" */),
  "component---src-pages-produtos-mre-index-tsx": () => import("./../../../src/pages/produtos/mre/index.tsx" /* webpackChunkName: "component---src-pages-produtos-mre-index-tsx" */),
  "component---src-pages-produtos-mre-novidades-tsx": () => import("./../../../src/pages/produtos/mre/novidades.tsx" /* webpackChunkName: "component---src-pages-produtos-mre-novidades-tsx" */),
  "component---src-pages-produtos-mrmobile-index-tsx": () => import("./../../../src/pages/produtos/mrmobile/index.tsx" /* webpackChunkName: "component---src-pages-produtos-mrmobile-index-tsx" */),
  "component---src-pages-produtos-mrmobile-novidades-tsx": () => import("./../../../src/pages/produtos/mrmobile/novidades.tsx" /* webpackChunkName: "component---src-pages-produtos-mrmobile-novidades-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */)
}

